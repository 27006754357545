<template>
  <div>
    <div>上海瑆皓科技有限公司官方主页</div>
  </div>
</template>
<script>

// Home.vue
export default {
  name: "XH-AI-Home",
  data() {
    return {
      vs: {
        "xh-ui": [
        ],
        "item": [],
        "other": []
      },
    }
  },
  computed: {
    username() {
      // We will see what `params` is shortly
      return this.$route.params
    },
  },
  methods: {

  },
  components: {
  },
}
</script>
<style scoped>
.advantage-border {
  padding: 0px 10px;
}

.inverse-background {
  background-color: #343434;

}

.row {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  padding: 6px 2px;
}
.title {
  font-weight: bold;
}

.bold-center {
  flex: 3;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}

.first {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  text-align: left;
}
.vs {
  flex: 1;
  color: cornflowerblue;
}
.second {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
</style>