<template>
  <div>
    <div class="info">
      上海瑆皓科技有限公司是一家专注于视觉表达，服务于品牌建设的公司。为客户提供专业的视觉设计服务，包括品牌视觉设计、产品视觉设计、网站视觉设计、移动端视觉设计、UI设计、VI设计、海报设计、画册设计、包装设计、展览展示设计、空间设计、动画设计、影视后期等。
    </div>
  </div>
</template>
<script>
export default {
  name: "XH-AI-About",
  computed: {

  },
  methods: {

  },
}
</script>
<style scoped>
.info {
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
}
  .info div {
    margin-bottom: 10px;
  }
</style>