<template>
    <div>
      <div>成功案例展示页</div>
      <div>更多</div>
    </div>
  </template>
  <script>
  // Case.vue
  export default {
    name: "XH-AI-Case",
    data() {
      return {
        items: []
      };
    },
    computed: {

    },
    created() {
      for (let i=1; i < 17; i++) {
        this.items.push(`assets/${i}.PNG`)
      }
    },
    methods: {
  
    },
  }
  </script>
  <style scoped>
.title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}

.image-block {
  margin-top: 10px;
}
  </style>