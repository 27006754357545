<template>
  <div>
    <div>
        <van-steps direction="vertical" :active="active" @click-step="onStepClickEvent">
            <van-step>
                <p class="year">2023</p>
                <p class="info">上海瑆皓科技有限公司</p>
            </van-step>
        </van-steps>

    </div>
  </div>
</template>
<script>
export default {
  name: "XH-AI-History",
  data() {
    return {
      active: 0,
    };
  },
  computed: {

  },
  methods: {
    onStepClickEvent(index) {
        this.active = index
    }
  },
}
</script>
<style scoped>
p {
    text-align: left;
}
.year {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
}

.info {
    font-size: 14px;
}
</style>