<template>
    <div>
        <van-cell-group inset title="基础记账服务" v-if="productIndex == 1">
            <van-cell>
                <template #title>
                    <span class="custom-title">电商视觉</span>
                </template>
            </van-cell>
        </van-cell-group>

        <van-cell-group inset title="外派财务服务" v-if="productIndex == 2">
            <van-cell>
                <template #title>
                    <span class="custom-title">IP设计服务+</span>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
  </template>
  <script>
  // Home.vue
  export default {
    name: "XH-AI-Product",
    data() {
      return {
        productIndex: null,
      };
    },
    computed: {

    },
    created() {
        this.productIndex = this.$route.params.productIndex
        this.$watch('$route.params.productIndex', (val) => {
            this.productIndex = val
        })
    },
    methods: {
  
    },
  }
  </script>
  <style scoped>
  .custom-title {
    display: flex;
    flex: 1;
  }
  </style>